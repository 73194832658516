import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import { db } from '../firebase/config';
import { doc, setDoc, collection, writeBatch } from '@firebase/firestore';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [fadeOut, setFadeOut] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practiceName: '',
    email: '',
    password: '',
    confirmPassword: '',
    streetAddress: '',
    suite: '',
    city: '',
    postalCode: '',
    veterinarians: 1,
    termsAccepted: false,
    pimsProvider: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 12,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    
    const failedRequirements = Object.entries(requirements)
      .filter(([_, passes]) => !passes)
      .map(([requirement]) => requirement);
    
    return {
      isValid: failedRequirements.length === 0,
      failedRequirements
    };
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    setError('');

    if (step === 1) {
      if (!formData.termsAccepted) {
        setError(
          <span className="text-red-500 text-xs">
            Please accept the terms and conditions
          </span>
        );
        return;
      }

      const { isValid, failedRequirements } = validatePassword(formData.password);
      if (!isValid) {
        const requirementMessages = {
          length: 'At least 12 characters',
          uppercase: 'Uppercase letters',
          lowercase: 'Lowercase letters',
          number: 'Numbers',
          special: 'Special characters (!@#$%^&*(),.?":{}|<>)'
        };

        const missingRequirements = failedRequirements.map(req => requirementMessages[req]);
        setError(
          <div className="text-red-500 text-xs mt-2">
            Password must contain:
            <ul className="list-disc list-inside space-y-1 mt-1">
              {missingRequirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>
        );
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setError(
          <span className="text-red-500 text-xs">
            Passwords do not match
          </span>
        );
        return;
      }

      setLoading(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // Send verification email
        await sendEmailVerification(userCredential.user);

        const userId = userCredential.user.uid;

        const clinicRef = doc(collection(db, 'clinics'));
        
        await setDoc(clinicRef, {
          practiceName: formData.practiceName,
          createdAt: new Date().toISOString(),
          owner: userId,
          admins: [userId]
        });

        await setDoc(
          doc(db, 'clinics', clinicRef.id, 'users', userId),
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: 'admin',
            createdAt: new Date().toISOString(),
          }
        );

        localStorage.setItem('clinicId', clinicRef.id);

        setFadeOut(true);
        setTimeout(() => {
          setStep(prev => prev + 1);
          setFadeOut(false);
        }, 300);
      } catch (error) {
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
      } finally {
        setLoading(false);
      }
    } else if (step === 3) {
      try {
        setLoading(true);
        const clinicId = localStorage.getItem('clinicId');
        
        await setDoc(doc(db, 'clinics', clinicId), {
          address: {
            address: formData.streetAddress,
            city: formData.city,
            country: country,
            state: region,
            zipcode: formData.postalCode
          },
          PIMs: formData.pimsProvider
        }, { merge: true });

        setFadeOut(true);
        setTimeout(() => {
          setStep(prev => prev + 1);
          setFadeOut(false);
        }, 300);
      } catch (error) {
        console.error('Error:', error);
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
      } finally {
        setLoading(false);
      }
    } else if (step === 4) {
      try {
        setLoading(true);
        const userId = auth.currentUser?.uid;
        if (!userId) {
          throw new Error('No authenticated user found');
        }
        
        const clinicId = localStorage.getItem('clinicId');
        if (!clinicId) {
          throw new Error('No clinic ID found');
        }

        const currentTime = new Date();
        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 2);

        const batch = writeBatch(db);

        batch.update(doc(db, 'clinics', clinicId), {
          numberOfDoctors: formData.veterinarians,
          paymentPeriod: billingCycle
        });

        const quickPicksRef = collection(db, 'clinics', clinicId, 'users', userId, 'settings', 'quickPicks', 'quickPickList');
        
        batch.set(doc(quickPicksRef), {
          label: "Surgery",
          value: "Summarize this patients surgical history"
        });

        batch.set(doc(quickPicksRef), {
          label: "Behavior",
          value: "Has this patient had any behavioral issues?"
        });

        const scribeRef = doc(db, 'clinics', clinicId, 'users', userId, 'settings', 'scribe');
        batch.set(scribeRef, {
          usingClientDischargeDefaultTemplate: true,
          usingSoapNoteDefaultTemplate: true
        });

        const tasksRef = doc(collection(db, 'clinics', clinicId, 'users', userId, 'tasks'));
        batch.set(tasksRef, {
          createdAt: currentTime,
          description: "Explore LyraVet!",
          dueDate: dueDate,
          lastModified: currentTime,
          patientId: "test",
          patientName: "Lyra",
          priority: "High",
          status: "pending",
          title: "Getting Started"
        });

        await batch.commit();
        navigate('/login');
      } catch (error) {
        console.error('Error:', error);
        setError(
          <span className="text-red-500 text-xs">
            {error.message}
          </span>
        );
      } finally {
        setLoading(false);
      }
    } else {
      setFadeOut(true);
      setTimeout(() => {
        setStep(prev => prev + 1);
        setFadeOut(false);
      }, 300);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setFadeOut(true);
    setTimeout(() => {
      setStep(prev => prev - 1);
      setFadeOut(false);
    }, 300);
  };

  const getButtonText = () => {
    if (step === 1) {
      return loading ? 'Creating Account...' : 'Continue';
    }
    return 'Continue';
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[460px] mx-auto pt-24 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-6 shadow-xl border border-gray-200/20">
              {/* Back Arrow - Only show on steps 2 and 3 */}
              {step > 1 && (
                <button
                  onClick={handleBack}
                  className="absolute text-gray-600 hover:text-gray-800 transition-colors p-2 -ml-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}

              {/* Step 1: Initial Sign Up */}
              {step === 1 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Create Account</h1>
                    <p className="text-sm text-gray-600 mt-1">Join Lyra for Veterinary Practices</p>
                  </div>
                
                  <form className="space-y-4">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="First name"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Last name"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Practice Name
                      </label>
                      <input
                        type="text"
                        name="practiceName"
                        value={formData.practiceName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your practice name"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your email"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Create password"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-[#00B8D4] border-gray-300 rounded"
                      />
                      <label className="ml-2 text-xs text-gray-700">
                        I agree to the{' '}
                        <Link to="/terms" className="text-[#00B8D4] hover:text-[#008CA1]">
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link to="/privacy" className="text-[#00B8D4] hover:text-[#008CA1]">
                          Privacy Policy
                        </Link>
                      </label>
                    </div>

                    {error && (
                      <div className="mt-2">
                        {error}
                      </div>
                    )}

                    <button
                      onClick={handleNextStep}
                      disabled={step === 1 && loading}
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                               disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {getButtonText()}
                    </button>
                  </form>

                  <div className="mt-4 text-center text-xs text-gray-600">
                    Already have an account?{' '}
                    <Link to="/login" className="text-[#00B8D4] hover:text-[#008CA1] font-medium">
                      Sign in
                    </Link>
                  </div>
                </div>
              )}

              {/* Step 2: Setup Process List */}
              {step === 2 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Setup Process</h1>
                    <p className="text-sm text-gray-600 mt-1">Here's what happens next</p>
                  </div>

                  <div className="space-y-8 mt-8">
                    {/* Step 1 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        1
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Complete Setup</h3>
                        <p className="text-sm text-gray-600">
                        Tell us about your clinic - we'll personalize Lyra to your practice!
                        </p>
                      </div>
                    </div>

                    {/* Step 2 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        2
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Verify Your Account</h3>
                        <p className="text-sm text-gray-600">
                          Don't forget to check your spam folder.
                        </p>
                      </div>
                    </div>

                    {/* Step 3 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        3
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Start Exploring</h3>
                        <p className="text-sm text-gray-600">
                            It takes 24-48 hours to complete your PIMs integration. Too excited to wait? Login to start using features that don't require patient data.
                        </p>
                      </div>
                    </div>

                    {/* Step 4 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        4
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Mission Complete</h3>
                        <p className="text-sm text-gray-600">
                            Enjoy everything Lyra has to offer!
                            Don't forget to check out the LyraVet iOS app!
                        </p>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleNextStep}
                    disabled={step === 1 && loading}
                    className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                             hover:bg-[#008CA1] transition-colors text-sm font-medium mt-8
                             disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {getButtonText()}
                  </button>
                </div>
              )}

              {/* Step 3: Demographics */}
              {step === 3 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Practice Info</h1>
                    <p className="text-sm text-gray-600 mt-1">Tell us about your practice</p>
                  </div>

                  <form className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Street Address
                      </label>
                      <input
                        type="text"
                        name="streetAddress"
                        value={formData.streetAddress}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter street address"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Suite/Unit (Optional)
                      </label>
                      <input
                        type="text"
                        name="suite"
                        value={formData.suite}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Suite or unit number"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Country
                        </label>
                        <CountryDropdown
                          value={country}
                          onChange={(val) => setCountry(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          State/Province
                        </label>
                        <RegionDropdown
                          country={country}
                          value={region}
                          onChange={(val) => setRegion(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                          disableWhenEmpty={true}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter city"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter postal code"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        PIMs/EMR Provider
                      </label>
                      <input
                        type="text"
                        name="pimsProvider"
                        value={formData.pimsProvider}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your EMR/PIMs provider"
                      />
                    </div>

                    <button
                      onClick={handleNextStep}
                      disabled={step === 1 && loading}
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                               disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {getButtonText()}
                    </button>
                  </form>
                </div>
              )}

              {/* Step 4: Plan Selection */}
              {step === 4 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Select Your Plan</h1>
                    <p className="text-sm text-gray-600 mt-1">Choose the best option for your practice</p>
                  </div>

                  <form className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        How many veterinarians will be using the LyraVet?
                      </label>
                      <input
                        type="range"
                        min="1"
                        max="9"
                        name="veterinarians"
                        value={formData.veterinarians}
                        onChange={handleInputChange}
                        className="w-full h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-[#00B8D4]"
                      />
                      <div className="flex justify-between text-xs text-gray-600 mt-1">
                        <span>1</span>
                        <span>9+</span>
                      </div>
                    </div>

                    <div className="flex justify-center space-x-4 py-2">
                      <button
                        type="button"
                        onClick={() => setBillingCycle('monthly')}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                  ${billingCycle === 'monthly' 
                                    ? 'bg-[#00B8D4] text-white' 
                                    : 'bg-gray-100 text-gray-600'}`}
                      >
                        Monthly
                      </button>
                      <button
                        type="button"
                        onClick={() => setBillingCycle('annual')}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                  ${billingCycle === 'annual' 
                                    ? 'bg-[#00B8D4] text-white' 
                                    : 'bg-gray-100 text-gray-600'}`}
                      >
                        Annual
                      </button>
                    </div>

                    <div className="text-center py-4">
                      <span className="text-3xl font-bold text-gray-800">$200</span>
                      <span className="text-gray-600">/month</span>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Promo Code (Optional)
                      </label>
                      <input
                        type="text"
                        name="promoCode"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter promo code"
                      />
                    </div>

                    <button
                      type="submit"
                      onClick={handleNextStep}
                      disabled={loading}
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2
                               disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {loading ? 'Creating Account...' : 'Complete Setup'}
                    </button>
                  </form>

                  {/* Add error message display */}
                  {error && (
                    <p className="text-red-500 text-sm text-center mt-2">
                      {error}
                    </p>
                  )}
                </div>
              )}
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default SignUpPage;
