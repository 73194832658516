import React from 'react';
import { Link } from 'react-router-dom';
import { Illustration } from './Illustration';

const TrustedBy = () => (
  <div className="mt-12 sm:mt-16 px-4 sm:px-6 lg:px-0">
    <p className="text-sm text-gray-500 mb-6">Integrates with leading veterinary software</p>
    <div className="flex items-center justify-center lg:justify-start gap-6 sm:gap-8 overflow-x-auto pb-4">
      <img 
        src="/pulse-logo.png" 
        alt="Pulse" 
        className="h-10 sm:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
      <img 
        src="/impromed-logo.png" 
        alt="Impromed" 
        className="h-10 sm:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
      <img 
        src="/avimark-logo.png" 
        alt="Avimark" 
        className="h-10 sm:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
    </div>
  </div>
);

export const Hero = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 pt-12 sm:pt-16 lg:pt-20 pb-2">
      <div className="relative flex flex-col lg:flex-row items-center gap-8 sm:gap-12">
        {/* Illustration positioned absolutely */}
        <div className="absolute right-0 top-0 hidden lg:block scale-150 opacity-0 lg:opacity-100 w-[650px]">
          <Illustration />
        </div>
        
        {/* Content with higher z-index */}
        <div className="flex-1 text-center lg:text-left relative z-10">
          <div className="px-4 sm:px-6 lg:px-0">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold leading-[1.1] tracking-tight">
              Organize.
              <br />
              Streamline.
              <br />
              Care.
            </h1>
            <p className="text-base sm:text-lg text-gray-600 mt-6 mb-8 max-w-3xl">
              Your space to streamline veterinary work and enhance patient care.
              </p>
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-3">
              <a 
                href="https://calendly.com/admin-lyravet/demo"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#00B8D4] text-white px-4 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium w-[200px] sm:w-[160px] mx-auto sm:mx-0 text-center"
              >
                Request a demo
              </a>
              <Link 
                to="/signup"
                className="border-2 border-[#00B8D4] text-[#00B8D4] px-4 py-2 rounded-lg hover:bg-[#00B8D4] hover:text-white transition-colors text-base font-medium w-[200px] sm:w-[160px] mx-auto sm:mx-0 text-center"
              >
                Start Free Trial
              </Link>
            </div>
          </div>
          <TrustedBy />
        </div>
        
        {/* Spacer div to maintain layout */}
        <div className="hidden lg:block flex-1" />
      </div>
    </div>
  );
};