import React from 'react';
import { Clock, MessageSquare, FileText, ListChecks, Sparkles } from 'lucide-react';

export const FeatureCards = () => {
  const features = [
    {
      icon: <Clock className="h-8 w-8 text-cosmic-cyan" />,
      title: "Mission Brief",
      description: "Begin each day with confidence using AI-powered patient summaries. Get concise, clinically relevant information for all scheduled appointments, allowing you to focus on delivering excellent care without sifting through extensive records.",
      illustration: "/illustrations/daily-primer.svg",
      image: "/mission-brief.png",
      highlights: [
        "At-a-glance patient overviews",
        "Automated clinical summaries",
        "Prioritized health concerns"
      ]
    },
    {
      icon: <FileText className="h-8 w-8 text-cosmic-cyan" />,
      title: "Medical Scribe",
      description: "Transform your appointments into thorough medical notes effortlessly. Whether you prefer voice recording or quick bullet points, our AI scribe handles the heavy lifting, ensuring you spend less time on paperwork and more time with patients.",
      illustration: "/illustrations/medical-scribe.svg",
      image: "/scribe-output.png",
      highlights: [
        "Voice-to-SOAP conversion",
        "Customized to match your style and personality",
        "Automated medical formatting"
      ]
    },
    {
      icon: <MessageSquare className="h-8 w-8 text-cosmic-cyan" />,
      title: "Chat Support",
      description: "Unlock the full potential of your patient's medical history with AI-powered insights. Quickly retrieve specific information, track symptom progression, and refine care plans through natural conversation with your patient's complete history.",
      illustration: "/illustrations/patient-explorer.svg",
      image: "/chat.png",
      highlights: [
        "Intelligent history analysis",
        "Lab result comparisons",
        "Treatment timeline tracking"
      ]
    },
    {
      icon: <ListChecks className="h-8 w-8 text-cosmic-cyan" />,
      title: "Task Checklist",
      description: "Experience the perfect blend of AI assistance and personal control. From patient follow-ups to medication refills, everything is organized by priority in one central hub, ensuring nothing falls through the cracks. Our AI automatically captures tasks from your medical notes, and you can easily add your own tasks on the fly.",
      illustration: "/illustrations/academic-search.svg",
      image: "/task-checklist.png",
      highlights: [
        <>AI-powered task detection <Sparkles className="inline-block h-4 w-4 text-cosmic-cyan" /></>,
        "Quick manual task creation",
        "Smart priority organization"
      ]
    }
  ];

  return (
    <div id="featurecards" className="max-w-7xl mx-auto px-4 sm:px-6 py-0 sm:py-0">
      <div className="space-y-16 sm:space-y-32">
        {features.map((feature, index) => (
          <div 
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
            } items-center gap-8 sm:gap-12`}
          >
            {/* Content Side */}
            <div className="flex-1 space-y-4 sm:space-y-6 text-center lg:text-left">
              <div className="inline-block p-3 bg-cosmic-cyan/10 rounded-2xl">
                {feature.icon}
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold text-deep-space">
                {feature.title}
              </h2>
              <p className="text-lg sm:text-xl text-gray-600">
                {feature.description}
              </p>
              {feature.subtext && (
                <p className="text-sm text-cosmic-cyan italic">
                  {feature.subtext}
                </p>
              )}
              <ul className="space-y-3 inline-block text-left">
                {feature.highlights.map((highlight, i) => (
                  <li key={i} className="flex items-center gap-3">
                    <div className="h-1.5 w-1.5 rounded-full bg-cosmic-cyan flex-shrink-0" />
                    <span className="text-gray-700 text-base sm:text-lg">{highlight}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Illustration Side */}
            <div className="flex-1 w-full max-w-[400px] lg:max-w-none">
              {feature.title === "Mission Brief" ? (
                <div className="h-[500px] overflow-y-auto space-y-4 p-2">
                  {[
                    {
                      text: "Henry has been experiencing anxiety at home, characterized by pacing, whining, and fidgeting behaviors. Trazodone was recommended over fluoxetine to manage these symptoms...",
                      funFact: "Henry goes to daycare once a week. His owners mentioned that he loves to watch movies that have dogs in them."
                    },
                    {
                      text: "Katie has been experiencing chronic health issues over the past year, including weight loss, intermittent diarrhea, and vomiting. Diagnosed with suspected chronic pancreatitis...",
                      funFact: "Kate loves giving high fives with both paws, especially when treats are involved!"
                    },
                    {
                      text: "Indie has a history of mild dental tartar noted during a general exam in January 2024. In March 2024, Indie was treated for a UTI with a Convenia injection...",
                      funFact: "Indie the cat got her name from Indiana Jones - she's just as adventurous, but prefers chasing toy mice over ancient artifacts!"
                    }
                  ].map((summary, i) => (
                    <div 
                      key={i} 
                      className="backdrop-blur-sm bg-white/90 rounded-2xl p-6 border border-gray-100 shadow-lg hover:bg-white/95 transition-all"
                    >
                      <p className="text-gray-700 text-sm mb-4">{summary.text}</p>
                      <div className="flex items-center gap-2 text-cosmic-cyan">
                        <span className="text-xl">💡</span>
                        <p className="text-sm italic">{summary.funFact}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : feature.title === "Chat Support" ? (
                <div className="relative h-[500px]">
                  <div className="h-full overflow-y-auto space-y-4 p-4">
                    <div className="space-y-4">
                      <div className="flex justify-end">
                        <div className="bg-cosmic-cyan/10 rounded-2xl rounded-tr-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">Please give me differentials for a dog with intermittent PU/PD?</p>
                        </div>
                      </div>
                      
                      <div className="flex justify-start">
                        <div className="bg-gray-100 rounded-2xl rounded-tl-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">Certainly. Here are some key differentials for a dog presenting with intermittent polyuria and polydipsia (PU/PD):</p>
                          
                          <div className="text-sm text-gray-700 space-y-2 mt-2">
                            <div>
                              <p className="font-medium">1. Endocrine disorders:</p>
                              <ul className="list-disc pl-6">
                                <li>Hyperadrenocorticism (Cushing's disease)</li>
                                <li>Diabetes mellitus</li>
                                <li>Hypoadrenocorticism (Addison's disease)</li>
                                <li>Hyperthyroidism (rare in dogs)</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">2. Renal disorders:</p>
                              <ul className="list-disc pl-6">
                                <li>Chronic kidney disease</li>
                                <li>Acute kidney injury</li>
                                <li>Pyelonephritis</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">3. Hepatic disorders:</p>
                              <ul className="list-disc pl-6">
                                <li>Hepatic insufficiency</li>
                                <li>Portosystemic shunt</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">4. Electrolyte imbalances:</p>
                              <ul className="list-disc pl-6">
                                <li>Hypercalcemia</li>
                                <li>Hypokalemia</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">5. Urinary tract infections</p>
                            </div>

                            <div>
                              <p className="font-medium">6. Psychogenic polydipsia</p>
                            </div>

                            <div>
                              <p className="font-medium">7. Iatrogenic causes:</p>
                              <ul className="list-disc pl-6">
                                <li>Glucocorticoid administration</li>
                                <li>Diuretic therapy</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">8. Hypoglycemia (reactive PU/PD)</p>
                            </div>

                            <div>
                              <p className="font-medium">9. Central diabetes insipidus</p>
                            </div>

                            <div>
                              <p className="font-medium">10. Nephrogenic diabetes insipidus</p>
                            </div>
                          </div>

                          <p className="text-sm text-gray-700 mt-4">
                            It's important to note that the intermittent nature of the PU/PD may suggest certain differentials over others, and a thorough history, physical examination, and appropriate diagnostic tests are crucial for accurate diagnosis.
                          </p>

                          <p className="text-sm text-gray-600 italic mt-2">
                            Citation: Ettinger, S.J., Feldman, E.C., & Côté, E. (2017). Textbook of Veterinary Internal Medicine: Diseases of the Dog and the Cat (8th ed.). Elsevier. Chapter 84: Polyuria and Polydipsia.
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <div className="bg-cosmic-cyan/10 rounded-2xl rounded-tr-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">His bloodwork is normal with a USG of 1.010. He has proteinuria on dipstick. What would be good next diagnostics?</p>
                        </div>
                      </div>

                      <div className="flex justify-start">
                        <div className="bg-gray-100 rounded-2xl rounded-tl-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">Given the information provided, including normal bloodwork, a urine specific gravity (USG) of 1.010, and proteinuria on dipstick, the following diagnostics would be appropriate next steps:</p>
                          
                          <div className="text-sm text-gray-700 space-y-3 mt-3">
                            <div>
                              <p className="font-medium">1. Urine Protein:Creatinine Ratio (UPC):</p>
                              <p className="pl-4">To quantify and confirm the degree of proteinuria, as dipstick results can be affected by urine concentration.</p>
                            </div>

                            <div>
                              <p className="font-medium">2. Complete Urinalysis with Sediment Examination:</p>
                              <p className="pl-4">To evaluate for presence of cells, casts, crystals, or bacteria that might indicate underlying urinary tract disease.</p>
                            </div>

                            <div>
                              <p className="font-medium">3. Urine Culture and Sensitivity:</p>
                              <p className="pl-4">To rule out urinary tract infection, which can cause proteinuria and dilute urine.</p>
                            </div>

                            <div>
                              <p className="font-medium">4. Abdominal Ultrasound:</p>
                              <p className="pl-4">To assess kidney architecture, size, and echogenicity, as well as to evaluate for urinary bladder abnormalities or other abdominal pathologies.</p>
                            </div>

                            <div>
                              <p className="font-medium">5. Blood Pressure Measurement:</p>
                              <p className="pl-4">Hypertension can cause proteinuria and is associated with various underlying conditions.</p>
                            </div>

                            <div>
                              <p className="font-medium">6. Symmetric Dimethylarginine (SDMA):</p>
                              <p className="pl-4">A more sensitive marker of early kidney dysfunction than creatinine.</p>
                            </div>

                            <div>
                              <p className="font-medium">7. Adrenocorticotropic Hormone (ACTH) Stimulation Test or Low-Dose Dexamethasone Suppression Test:</p>
                              <p className="pl-4">To rule out hyperadrenocorticism, which can cause PU/PD and proteinuria.</p>
                            </div>

                            <div>
                              <p className="font-medium">8. Antinuclear Antibody (ANA) Test:</p>
                              <p className="pl-4">If immune-mediated disease is suspected as a cause of proteinuria.</p>
                            </div>

                            <div>
                              <p className="font-medium">9. Imaging Studies:</p>
                              <p className="pl-4">Thoracic radiographs to check for evidence of metastatic disease or other systemic illness.</p>
                            </div>
                          </div>

                          <p className="text-sm text-gray-700 mt-4">
                            These diagnostics will help further characterize the proteinuria, assess kidney function, and potentially identify underlying causes of the intermittent PU/PD.
                          </p>

                          <div className="text-sm text-gray-600 italic mt-4 space-y-2">
                            <p>Citations:</p>
                            <ol className="list-decimal pl-5 space-y-1">
                              <li>Littman, M.P. (2011). Protein-losing Nephropathy in Small Animals. Veterinary Clinics of North America: Small Animal Practice, 41(1), 31-62. https://doi.org/10.1016/j.cvsm.2010.09.006</li>
                              <li>Grauer, G.F. (2011). Proteinuria: Measurement and Interpretation. Topics in Companion Animal Medicine, 26(3), 121-127. https://doi.org/10.1053/j.tcam.2011.04.002</li>
                              <li>Polzin, D.J. (2017). Chronic Kidney Disease. In S.J. Ettinger, E.C. Feldman, & E. Côté (Eds.), Textbook of Veterinary Internal Medicine (8th ed., pp. 4693-4734). Elsevier.</li>
                            </ol>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <div className="bg-cosmic-cyan/10 rounded-2xl rounded-tr-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">Can you explain how the differentials would change if the UPC ratio was very high, mildly elevated, and normal?</p>
                        </div>
                      </div>

                      <div className="flex justify-start">
                        <div className="bg-gray-100 rounded-2xl rounded-tl-none px-4 py-2 max-w-[80%]">
                          <p className="text-sm text-gray-700">Certainly. The Urine Protein:Creatinine (UPC) ratio is an important diagnostic tool that helps quantify proteinuria and can guide the differential diagnosis. I'll explain how the differentials might change based on different UPC ratio results:</p>
                          
                          <div className="text-sm text-gray-700 space-y-4 mt-4">
                            <div>
                              <p className="font-medium">1. Normal UPC ratio ({"<"} 0.2 in dogs):</p>
                              <p className="pl-4 mt-2">If the UPC ratio is normal despite the positive dipstick result, differentials would focus more on:</p>
                              <ul className="list-disc pl-8 mt-1">
                                <li>Prerenal causes of PU/PD (e.g., psychogenic polydipsia, central diabetes insipidus)</li>
                                <li>Endocrine disorders (e.g., hyperadrenocorticism, diabetes mellitus)</li>
                                <li>Hepatic disorders</li>
                                <li>Electrolyte imbalances</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">2. Mildly elevated UPC ratio (0.2-2.0 in dogs):</p>
                              <p className="pl-4 mt-2">A mildly elevated UPC ratio may indicate:</p>
                              <ul className="list-disc pl-8 mt-1">
                                <li>Early stages of glomerular disease</li>
                                <li>Tubular dysfunction</li>
                                <li>Urinary tract inflammation or infection</li>
                                <li>Hypertension</li>
                                <li>Early stages of some systemic diseases</li>
                              </ul>
                            </div>

                            <div>
                              <p className="font-medium">3. Very high UPC ratio ({">"}2.0 in dogs):</p>
                              <p className="pl-4 mt-2">A significantly elevated UPC ratio strongly suggests glomerular disease. Differentials would focus on:</p>
                              <ul className="list-disc pl-8 mt-1">
                                <li>Immune-complex glomerulonephritis</li>
                                <li>Amyloidosis</li>
                                <li>Hereditary nephropathies</li>
                                <li>Chronic kidney disease with significant glomerular damage</li>
                                <li>Systemic diseases with renal involvement (e.g., systemic lupus erythematosus, Lyme disease)</li>
                                <li>Neoplasia (particularly lymphoma)</li>
                                <li>Infectious diseases (e.g., leishmaniasis, ehrlichiosis)</li>
                              </ul>
                            </div>
                          </div>

                          <p className="text-sm text-gray-700 mt-4">
                            It's important to note that the UPC ratio should be interpreted in conjunction with other clinical findings, urinalysis results, and patient history. The persistence of proteinuria should also be confirmed with repeated measurements.
                          </p>

                          <div className="text-sm text-gray-600 italic mt-4 space-y-2">
                            <p>Citations:</p>
                            <ol className="list-decimal pl-5 space-y-1">
                              <li>Grauer, G.F. (2011). Proteinuria: Measurement and Interpretation. Topics in Companion Animal Medicine, 26(3), 121-127. https://doi.org/10.1053/j.tcam.2011.04.002</li>
                              <li>Littman, M.P., Daminet, S., Grauer, G.F., Lees, G.E., & van Dongen, A.M. (2013). Consensus Recommendations for the Diagnostic Investigation of Dogs with Suspected Glomerular Disease. Journal of Veterinary Internal Medicine, 27(S1), S19-S26. https://doi.org/10.1111/jvim.12223</li>
                              <li>Harley, L., & Langston, C. (2012). Proteinuria in dogs and cats. The Canadian Veterinary Journal, 53(6), 631-638.</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : feature.title === "Task Checklist" ? (
                <div className="h-[500px] p-4">
                  <div className="grid grid-cols-2 gap-4 h-full">
                    {/* High Priority Tasks */}
                    <div className="backdrop-blur-sm bg-gray-50/50 rounded-2xl p-6 border border-gray-100 shadow-lg relative overflow-hidden transition-all duration-200 hover:scale-[1.02] hover:shadow-xl">
                      <div className="absolute left-0 top-0 bottom-0 w-1 bg-red-400" />
                      <div className="flex items-start gap-3">
                        <div className="h-5 w-5 rounded-full border-2 border-red-300 flex-shrink-0 mt-0.5" />
                        <div className="space-y-2 min-h-[120px]">
                          <div className="text-xs font-medium text-red-500">High Priority</div>
                          <h4 className="font-medium text-gray-900">Order More Vaccines</h4>
                          <p className="text-sm text-gray-600 line-clamp-2">Out of rabies vaccines</p>
                          <div className="flex items-center text-sm text-gray-500 mt-auto">
                            <span>Jan 9, 2:45 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="backdrop-blur-sm bg-gray-50/50 rounded-2xl p-6 border border-gray-100 shadow-lg relative overflow-hidden transition-all duration-200 hover:scale-[1.02] hover:shadow-xl">
                      <div className="absolute left-0 top-0 bottom-0 w-1 bg-red-400" />
                      <div className="absolute top-2 right-2 group">
                        <Sparkles className="h-4 w-4 text-cosmic-cyan" />
                        <div className="invisible group-hover:visible absolute right-0 top-6 min-w-[140px] max-w-[400px] p-3 bg-white/90 backdrop-blur-sm text-xs text-gray-600 rounded-lg shadow-lg border border-gray-100 z-20">
                          <div className="break-words">
                            This task was automatically generated by AI Scribe based on the appointment recording
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start gap-3">
                        <div className="h-5 w-5 rounded-full border-2 border-red-300 flex-shrink-0 mt-0.5" />
                        <div className="space-y-2 min-h-[120px]">
                          <div className="text-xs font-medium text-red-500">High Priority</div>
                          <h4 className="font-medium text-gray-900">Call Specialist</h4>
                          <p className="text-sm text-gray-600 line-clamp-2">Patient: Henry<br />Call behaviorist for consult.</p>
                          <div className="flex items-center text-sm text-gray-500 mt-auto">
                            <span>Jan 9, 12:56 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Medium Priority Task */}
                    <div className="backdrop-blur-sm bg-gray-50/50 rounded-2xl p-6 border border-gray-100 shadow-lg relative overflow-hidden transition-all duration-200 hover:scale-[1.02] hover:shadow-xl">
                      <div className="absolute left-0 top-0 bottom-0 w-1 bg-yellow-400" />
                      <div className="flex items-start gap-3">
                        <div className="h-5 w-5 rounded-full border-2 border-yellow-300 flex-shrink-0 mt-0.5" />
                        <div className="space-y-2 min-h-[120px]">
                          <div className="text-xs font-medium text-yellow-500">Medium Priority</div>
                          <h4 className="font-medium text-gray-900">Review Labwork</h4>
                          <p className="text-sm text-gray-600 line-clamp-2">Patient: Henry</p>
                          <div className="flex items-center text-sm text-gray-500 mt-auto">
                            <span>Jan 9, 12:57 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Low Priority Task */}
                    <div className="backdrop-blur-sm bg-gray-50/50 rounded-2xl p-6 border border-gray-100 shadow-lg relative overflow-hidden transition-all duration-200 hover:scale-[1.02] hover:shadow-xl">
                      <div className="absolute left-0 top-0 bottom-0 w-1 bg-green-400" />
                      <div className="flex items-start gap-3">
                        <div className="h-5 w-5 rounded-full border-2 border-green-300 flex-shrink-0 mt-0.5" />
                        <div className="space-y-2 min-h-[120px]">
                          <div className="text-xs font-medium text-green-500">Low Priority</div>
                          <h4 className="font-medium text-gray-900">Send Food Samples</h4>
                          <p className="text-sm text-gray-600 line-clamp-2">Patient: Indie<br />The client asked for a sample of Hill's Science Diet, we didn't have any. Send ASAP</p>
                          <div className="flex items-center text-sm text-gray-500 mt-auto">
                            <span>Jan 9, 2:30 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-auto rounded-2xl bg-gradient-to-br from-cosmic-cyan-light/10 to-deep-space-light/10 p-2 sm:p-4">
                  <div className="w-full relative overflow-hidden rounded-3xl bg-white">
                    <img 
                      src={feature.image}
                      alt={`${feature.title} Interface Example`}
                      className="w-full h-auto object-contain"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
