import React from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const EmailVerificationReminderPage = () => {
  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[500px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <div className="text-center">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                  Check Your Email
                </h1>
                <p className="text-gray-600 mb-6">
                  A verification link has been sent to your email address. 
                  Please check your inbox and spam folder to verify your account.
                </p>
                <div className="mt-6 text-sm text-gray-500">
                  Once verified, you can return to the login page to sign in.
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default EmailVerificationReminderPage; 