import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../firebase/config';
import { applyActionCode, signOut } from 'firebase/auth';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';

const EmailVerificationPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verificationState, setVerificationState] = useState('verifying'); // verifying, success, error
  const [error, setError] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      const actionCode = searchParams.get('oobCode');

      if (!actionCode) {
        setVerificationState('error');
        setError('Invalid verification link. Please request a new one.');
        return;
      }

      try {
        await applyActionCode(auth, actionCode);
        await signOut(auth); // Sign out user so they can sign in fresh with verified email
        setVerificationState('success');
      } catch (error) {
        setVerificationState('error');
        switch (error.code) {
          case 'auth/invalid-action-code':
            setError('The verification link has expired or already been used.');
            break;
          default:
            setError('An error occurred during verification. Please try again.');
        }
      }
    };

    verifyEmail();
  }, [searchParams]);

  const renderContent = () => {
    switch (verificationState) {
      case 'verifying':
        return (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#00B8D4] mx-auto mb-4"></div>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Verifying Your Email</h2>
            <p className="text-gray-600">Please wait while we verify your email address...</p>
          </div>
        );

      case 'success':
        return (
          <div className="text-center">
            <div className="mb-6">
              <svg className="w-16 h-16 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Email Verified Successfully!</h2>
            <p className="text-gray-600 mb-6">Your email has been verified. You can now sign in to your account.</p>
            <button
              onClick={() => navigate('/login')}
              className="bg-[#00B8D4] text-white px-6 py-3 rounded-lg 
                       hover:bg-[#008CA1] transition-colors text-base font-medium"
            >
              Sign In
            </button>
          </div>
        );

      case 'error':
        return (
          <div className="text-center">
            <div className="mb-6">
              <svg className="w-16 h-16 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Verification Failed</h2>
            <p className="text-red-500 mb-6">{error}</p>
            <button
              onClick={() => navigate('/login')}
              className="bg-[#00B8D4] text-white px-6 py-3 rounded-lg 
                       hover:bg-[#008CA1] transition-colors text-base font-medium"
            >
              Return to Login
            </button>
          </div>
        );
    }
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[500px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              {renderContent()}
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default EmailVerificationPage;