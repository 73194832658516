import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDozahTGejVbihJVEfRL1KJa67NPaSXR5Q",
  authDomain: "lyravet-ac8ca.firebaseapp.com",
  projectId: "lyravet-ac8ca",
  storageBucket: "lyravet-ac8ca.firebasestorage.app",
  messagingSenderId: "606783792729",
  appId: "1:606783792729:web:f3bc21036cf8ffc576b348",
  measurementId: "G-8PNBS77B1K"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
