// src/components/landing/ProductShowcase.jsx
import React, { useState, useEffect } from 'react';
import { Search, FileText, Calendar } from 'lucide-react';

const screenshots = [
  {
    id: 1,
    image: '/primer.jpeg',
    title: "Mission Brief",
    description: "Smart appointment scheduling",
    icon: <Calendar className="h-5 w-5 text-[#00B8D4]" />
  },
  {
    id: 2,
    image: '/scribe.jpeg',
    title: "Scribe",
    description: "Voice-powered medical notes",
    icon: <FileText className="h-5 w-5 text-[#00B8D4]" />
  },
  {
    id: 3,
    image: '/task-chat.jpeg', // Note: no 'public' in the path
    title: "Additional Features",
    description: "Task-Management & Chatbot",
    icon: <Search className="h-5 w-5 text-[#00B8D4]" />
  }
];

const ChromeFrame = ({ children }) => (
  <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full mx-auto">
    {/* Chrome Browser Bar */}
    <div className="bg-gray-100 px-4 py-2 border-b border-gray-200 flex items-center space-x-2">
      <div className="flex space-x-2">
        <div className="w-3 h-3 rounded-full bg-red-400"></div>
        <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
        <div className="w-3 h-3 rounded-full bg-green-400"></div>
      </div>
      <div className="flex-1 mx-4">
        <div className="bg-white rounded-md px-3 py-1 text-sm text-gray-500 text-center border border-gray-200">
          LyraVet.com
        </div>
      </div>
    </div>
    <div className="bg-[#F6F8FA]">
      {children}
    </div>
  </div>
);

const ScreenshotButton = ({ title, description, icon, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center px-4 py-3 rounded-lg transition-colors min-w-[200px] ${
      isActive 
        ? 'bg-gray-100' 
        : 'hover:bg-gray-50'
    }`}
  >
    <div className="text-left flex items-center">
      {icon}
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    </div>
  </button>
);

export const ProductShowcase = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isAutoplaying, setIsAutoplaying] = useState(true); // Add new state
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);

    useEffect(() => {
      // Only set up the interval if autoplaying is enabled
      if (!isAutoplaying) return;
      
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % screenshots.length);
      }, 5000);
      return () => clearInterval(timer);
    }, [isAutoplaying]); // Add isAutoplaying to dependencies

    const handleImageLoad = () => {
      setIsLoading(false);
    };
  
    const handleScreenshotClick = (index) => {
      setIsAutoplaying(false); // Disable autoplay on user interaction
      setCurrentIndex(index);
    };

    const checkScroll = () => {
      const container = document.getElementById('screenshot-scroll');
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollLeft < container.scrollWidth - container.clientWidth
        );
      }
    };

    useEffect(() => {
      const container = document.getElementById('screenshot-scroll');
      if (container) {
        container.addEventListener('scroll', checkScroll);
        // Initial check
        checkScroll();
      }
      return () => {
        if (container) {
          container.removeEventListener('scroll', checkScroll);
        }
      };
    }, []);

    const handlePrevButton = () => {
      setCurrentIndex((prev) => (prev - 1 + screenshots.length) % screenshots.length);
    };

    const handleNextButton = () => {
      setCurrentIndex((prev) => (prev + 1) % screenshots.length);
    };

    return (
        <div id="productshowcase" className="w-full px-4 sm:px-6 py-8">
          {/* Main Image Carousel */}
          <div className="max-w-[1300px] mx-auto">
            <div className="relative">
              <ChromeFrame>
                <div className="relative h-auto">
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                      <div className="animate-pulse text-gray-400">Loading...</div>
                    </div>
                  )}
                  <img
                    src={screenshots[currentIndex].image}
                    alt={screenshots[currentIndex].title}
                    className={`w-full h-auto object-contain transition-opacity duration-300 ${
                      isLoading ? 'opacity-0' : 'opacity-100'
                    }`}
                    onLoad={handleImageLoad}
                    onError={(e) => {
                      console.error('Image failed to load:', screenshots[currentIndex].image);
                      setIsLoading(false);
                    }}
                  />
                </div>
              </ChromeFrame>
            </div>
    
            {/* Screenshot Navigation */}
            <div className="mt-6">
              {/* Mobile view - single button with arrows */}
              <div className="md:hidden flex items-center justify-center space-x-4">
                <button
                  onClick={handlePrevButton}
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                  aria-label="Previous"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>

                <ScreenshotButton
                  {...screenshots[currentIndex]}
                  isActive={true}
                  onClick={() => handleScreenshotClick(currentIndex)}
                />

                <button
                  onClick={handleNextButton}
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                  aria-label="Next"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>

              {/* Desktop view - all buttons in a row */}
              <div className="hidden md:flex md:space-x-2 justify-center">
                {screenshots.map((screenshot, index) => (
                  <ScreenshotButton
                    key={screenshot.id}
                    {...screenshot}
                    isActive={currentIndex === index}
                    onClick={() => handleScreenshotClick(index)}
                  />
                ))}
              </div>
            </div>
    
            {/* Dot Indicators */}
            <div className="flex justify-center space-x-2 mt-4">
              {screenshots.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleScreenshotClick(index)} // Use new handler
                  className={`w-2 h-2 rounded-full transition-colors ${
                    index === currentIndex 
                      ? 'bg-cosmic-cyan' 
                      : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      );
    };

export default ProductShowcase;
