import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setError('');

    const auth = getAuth();
    
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
      setEmail('');
    } catch (err) {
      setError('Failed to reset password. Please check if the email is correct.');
      console.error('Password reset error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent flex items-center justify-center">
          <div className="max-w-[400px] w-full mx-auto px-4">
            <div className="bg-white/90 backdrop-blur-md rounded-lg p-8 shadow-lg">
              <img 
                src="/BWtransparentlogo.png" 
                alt="Lyra Logo" 
                className="w-16 h-16 mx-auto mb-6" 
              />
              <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
                Reset Password
              </h1>
              <p className="text-gray-600 text-center mb-6">
                Enter your email address and we'll send you instructions to reset your password.
              </p>
              
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label 
                    htmlFor="email" 
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 
                             focus:ring-[#00B8D4] focus:border-transparent outline-none"
                    required
                  />
                </div>

                {message && (
                  <div className="text-green-600 text-sm text-center">
                    {message}
                  </div>
                )}

                {error && (
                  <div className="text-red-600 text-sm text-center">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full px-4 py-2 bg-[#00B8D4] hover:bg-[#008CA1] text-white 
                           rounded-lg font-medium transition-colors disabled:bg-gray-400 
                           disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Sending...' : 'Reset Password'}
                </button>

                <div className="text-center">
                  <a 
                    href="/login" 
                    className="text-[#00B8D4] hover:text-[#008CA1] text-sm"
                  >
                    Back to Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SpaceBackground>
    </div>
  );
};

export default ForgotPasswordPage;
