import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (sectionId, event) => {
    event.preventDefault();
    setIsMenuOpen(false); // Close menu after clicking
    
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const headerOffset = sectionId === 'productshowcase' ? 100 : 
                           sectionId === 'featurecards' ? 10 : 
                           30;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/30 backdrop-blur-md border-b border-gray-200/20 z-50">
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-[60px]">
          {/* Logo + Company name */}
          <Link to="/" className="flex items-center flex-shrink-0">
            <img src="/BWtransparentlogo.png" alt="Lyra Logo" className="mr-2 w-10 h-10" />
            <div className="inline-flex items-center">
              <span className="text-[16px] font-bold text-gray-800 leading-none">LyraVet</span>
            </div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="#productshowcase" onClick={(e) => scrollToSection('productshowcase', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Product</a>
            <a href="#featurecards" onClick={(e) => scrollToSection('featurecards', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Features</a>
            <a href="#pricing" onClick={(e) => scrollToSection('pricing', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Pricing</a>
          </div>

          {/* Desktop Auth Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/login" className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">
              Log in
            </Link>
            <Link 
              to="/signup"
              className="bg-[#00B8D4] text-white px-6 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium min-w-[160px] text-center"
            >
              Start Free Trial
            </Link>
          </div>

          {/* Hamburger Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-[60px] left-0 right-0 bg-white border-b border-gray-200 shadow-lg">
            <div className="px-4 py-3 space-y-3">
              <a
                href="#productshowcase"
                onClick={(e) => scrollToSection('productshowcase', e)}
                className="block text-[14.5px] font-medium text-gray-800 hover:text-gray-900"
              >
                Product
              </a>
              <a
                href="#featurecards"
                onClick={(e) => scrollToSection('featurecards', e)}
                className="block text-[14.5px] font-medium text-gray-800 hover:text-gray-900"
              >
                Features
              </a>
              <a
                href="#pricing"
                onClick={(e) => scrollToSection('pricing', e)}
                className="block text-[14.5px] font-medium text-gray-800 hover:text-gray-900"
              >
                Pricing
              </a>
              <div className="pt-4 space-y-3">
                <Link
                  to="/login"
                  className="block text-[14.5px] font-medium text-gray-800 hover:text-gray-900"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Log in
                </Link>
                <Link
                  to="/signup"
                  className="block bg-[#00B8D4] text-white px-6 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium text-center"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Start Free Trial
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
