import React from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const CookiesPage = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[800px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">Cookie Policy</h1>
              <p className="text-gray-600 mb-8">
                <strong>Last Updated:</strong> {currentDate}
              </p>

              <div className="space-y-8 text-gray-700">
                {/* Introduction */}
                <section>
                  <p className="mb-6">
                    This Cookie Policy explains how Vetreum Technologies ("we", "us", or "our") uses 
                    cookies and similar technologies when you visit our website or use our services. 
                    This policy helps you understand what cookies are, how we use them, and the choices 
                    you have regarding their use.
                  </p>
                </section>

                {/* What Are Cookies */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">1. What Are Cookies?</h2>
                  <p>
                    Cookies are small text files that are placed on your device when you visit a website. 
                    They are widely used to make websites work more efficiently, provide basic 
                    functionality (such as remembering your preferences), and deliver a better, more 
                    personalized experience.
                  </p>
                </section>

                {/* Types of Cookies We Use */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Types of Cookies We Use</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.1 Essential Cookies</h3>
                      <p>
                        These cookies are necessary for the website to function properly. They enable 
                        basic functions like page navigation, secure areas access, and other essential 
                        features. The website cannot function properly without these cookies.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.2 Performance Cookies</h3>
                      <p>
                        These cookies help us understand how visitors interact with our website by 
                        collecting and reporting information anonymously. They help us improve website 
                        functionality and user experience.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.3 Functionality Cookies</h3>
                      <p>
                        These cookies allow the website to remember choices you make (such as your 
                        username, language, or region) and provide enhanced, more personal features.
                      </p>
                    </div>
                  </div>
                </section>

                {/* How We Use Cookies */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">3. How We Use Cookies</h2>
                  <p className="mb-4">We use cookies for the following purposes:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>To authenticate users and maintain session information</li>
                    <li>To remember your preferences and settings</li>
                    <li>To analyze website traffic and user behavior</li>
                    <li>To improve website performance and functionality</li>
                    <li>To provide a personalized user experience</li>
                  </ul>
                </section>

                {/* Third-Party Cookies */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">4. Third-Party Cookies</h2>
                  <p>
                    Some cookies are placed by third-party services that appear on our pages. We use 
                    third-party cookies for:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mt-4">
                    <li>Analytics (Google Analytics)</li>
                    <li>Payment processing</li>
                    <li>Authentication services</li>
                  </ul>
                </section>

                {/* Managing Cookies */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Managing Cookies</h2>
                  <p className="mb-4">
                    Most web browsers allow you to control cookies through their settings preferences. 
                    However, limiting cookies may affect the functionality of our website. To manage or 
                    delete cookies, you can:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Access your browser's cookie settings</li>
                    <li>Use your browser's privacy mode</li>
                    <li>Delete cookies manually through your browser</li>
                    <li>Use automated privacy protection tools</li>
                  </ul>
                </section>

                {/* Cookie Consent */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Cookie Consent</h2>
                  <p>
                    When you first visit our website, you will be asked to consent to our use of cookies. 
                    You can choose to accept or decline cookies. Essential cookies cannot be declined as 
                    they are necessary for the website to function properly.
                  </p>
                </section>

                {/* Updates to Cookie Policy */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">7. Updates to This Policy</h2>
                  <p>
                    We may update this Cookie Policy from time to time. Any changes will be posted on 
                    this page with an updated revision date. Please check back periodically to stay 
                    informed about our use of cookies.
                  </p>
                </section>

                {/* Contact Information */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">8. Contact Us</h2>
                  <p className="mb-2">
                    If you have any questions about our use of cookies, please contact us at:
                  </p>
                  <p>
                    Email:{' '}
                    <a href="mailto:contact@lyravet.com" className="text-[#00B8D4] hover:text-[#008CA1]">
                      contact@lyravet.com
                    </a>
                  </p>
                </section>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default CookiesPage; 