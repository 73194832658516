import React, { useEffect } from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Hero } from '../components/landing/Hero';
import { ProductShowcase } from '../components/landing/ProductShowcase';
import { FeatureCards } from '../components/landing/FeatureCards';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { Pricing } from '../components/landing/Pricing';
import { Contact } from '../components/landing/Contact';
import { Footer } from '../components/navigation/Footer';
import { useLocation } from 'react-router-dom';

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const sectionId = location.state.scrollTo;
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const headerOffset = sectionId === 'productshowcase' ? 100 : 
                           sectionId === 'featurecards' ? 10 : 
                           30;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [location]);

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[1300px] mx-auto px-4 sm:px-6 lg:px-8">
            <section className="pt-8 md:pt-12 lg:pt-16">
              <Hero />
            </section>

            <section className="mt-6 md:mt-8 lg:mt-12">
              <ProductShowcase />
            </section>

            <section className="mt-6 md:mt-8 lg:mt-12">
              <FeatureCards />
            </section>

            <section className="mt-6 md:mt-8 lg:mt-12">
              <Pricing />
            </section>

            <section className="mt-6 md:mt-8 lg:mt-12 mb-6 md:mb-8 lg:mb-12">
              <Contact />
            </section>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default LandingPage;
