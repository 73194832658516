import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

export const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'contacts'), formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error sending message. Please try again.');
    }
  };

  return (
    <section className="py-0 max-w-3xl mx-auto px-4" id="contact">
      {!isSubmitted && (
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-deep-space">Get in Touch</h2>
          <p className="text-xl text-gray-500">
            Have questions? We're here to help. Reach out to our team.
          </p>
        </div>
      )}

      {!isSubmitted ? (
        <form 
          className="space-y-8 transition-opacity duration-500" 
          onSubmit={handleSubmit}
        >
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                           focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                           placeholder-gray-400 transition-colors"
                placeholder="Enter your first name"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                           focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                           placeholder-gray-400 transition-colors"
                placeholder="Enter your last name"
                required
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                         focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                         placeholder-gray-400 transition-colors"
              placeholder="Enter your email"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Subject
            </label>
            <select
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                         focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                         placeholder-gray-400 transition-colors"
            >
              <option value="">Select a topic</option>
              <option value="general">General Inquiry</option>
              <option value="demo">Request a Demo</option>
              <option value="pricing">Pricing Questions</option>
              <option value="support">Technical Support</option>
              <option value="other">Other</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                         focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                         placeholder-gray-400 transition-colors resize-none"
              placeholder="What's on your mind?"
              required
            ></textarea>
          </div>

          <div className="text-center pt-4">
            <button
              type="submit"
              className="px-8 py-3 bg-[#00B8D4] hover:bg-[#00A5BE] text-white 
                       rounded-lg font-medium transition-colors inline-block
                       min-w-[200px]"
            >
              Send Message
            </button>
          </div>
        </form>
      ) : (
        <div 
          className="text-center py-16 transition-opacity duration-500 animate-fade-in"
        >
          <div className="mb-8 flex items-center justify-center">
            <svg 
              className="w-20 h-20 text-[#1A237E]" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
              />
            </svg>
          </div>
          <h3 className="text-4xl font-bold text-gray-800 mb-4">
            Thank You for Reaching Out!
          </h3>
          <p className="text-xl text-gray-600">
            We've received your message and will get back to you soon.
          </p>
        </div>
      )}
    </section>
  );
};
