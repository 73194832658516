import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import CookiesPage from './pages/CookiesPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';
import SignUpPage from './pages/SignUpPage';
import ComingSoonPage from './pages/ComingSoonPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import EmailVerificationReminderPage from './pages/EmailVerificationReminderPage';
import ForgotPasswordPage from './pages/ForgotPassword';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/verify-email" element={<EmailVerificationPage />} />
        <Route path="/verify-reminder" element={<EmailVerificationReminderPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      </Routes>
    </Router>
  );
}

export default App;